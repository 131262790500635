export const getImageURL = (input, thumb) => {
    if (!input) {
        return null;
    }

    var fn = input;
    fn = fn.toLowerCase();
    fn = fn.replace('sites/default/files/', '')
    fn = fn.replace(/[åäÅÄ]+/ig, 'a')
    fn = fn.replace(/[öÖ]+/ig, 'o')
    fn = fn.replace(/[^a-z0-9_.]/ig, '_')
    fn = fn.replace(/(png|gif|jpeg)$/ig, 'jpg')

    var url = 'https://storage.googleapis.com/redsnarecdn.possan.se/images/';
    // var url = 'https://redsnarecdn.possan.se/images/';

    if (thumb) {
        url += 'thumb/' + thumb + '/'
    }

    url += fn;

    // console.log('getImageURL(' + input + ',' + thumb + ') = ' + url);
    return url;
}

export const getAudioURL = (input) => {
    if (!input) {
        return null;
    }

    var fn = input;
    fn = fn.toLowerCase();
    fn = fn.replace('sites/default/files/stream', '')
    fn = fn.replace(/^\/[0-9]+\//i, '')
    fn = fn.replace(/[åäÅÄ]+/ig, 'a')
    fn = fn.replace(/[öÖ]+/ig, 'o')
    fn = fn.replace(/[^a-z0-9_.]/ig, '_')

    var url = 'https://storage.googleapis.com/redsnarecdn.possan.se/audio/';
    // var url = 'https://redsnarecdn.possan.se/images/';

    url += fn;

    return url;
}
