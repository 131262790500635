import React from 'react'
import { graphql } from "gatsby"
import * as utils from '../utils'
import Layout from "../components/layout"
import SocialLinks from "../components/sociallinks"

export const pageQuery = graphql`
  query ArtistComponentQuery($slug: String!) {
    main: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        nid
        vid
        cover_filepath
        photo_filepath
        image_filepath
        artists
        official
        spotifylink
      }
      headings {
        value
        depth
      }
    }
  }
`

const ReleaseListComponent = ({title, list}) => (
  list.length > 0 ?
  <div>
    <h3>{title}</h3>
    <div className="covers">
      {list.map(t => {
        let img = utils.getImageURL(t.frontmatter.cover_filepath || t.frontmatter.image_filepath, 300)
        return img ? <span className="cover">
          <a href={'/' + (t.fields && t.fields.slug)}>
            <img src={img} alt={t.frontmatter.title} width="150" height="150" />
          </a>
        </span> : null
      })}
    </div>
    <hr />
  </div> : null
)

const findReleases = (all, myid) => {
  return (all || []).map(t => {
    const rel = (t.frontmatter.artists || '').split(',')
    t._rel = rel
    t._check = myid.toString()
    t._match = rel.indexOf(myid.toString()) !== -1
    return t;
  }).filter(t => t._match && t.frontmatter.official === 1)
}

const findFeaturedOn = (all, myid) => {
  return (all || []).map(t => {
    const rel = (t.frontmatter.supportingartists || '').split(',')
    t._rel = rel
    t._check = myid.toString()
    t._match = rel.indexOf(myid.toString()) !== -1
    return t;
  }).filter(t => t._match && t.frontmatter.official === 1)
}

const ArtistComponent = (props) => {
  const {data} = props;
  let allReleases = props.pageContext.allReleases.edges.map(t => t.node);
  return <Layout>
    {
      (data.main.frontmatter.photo_filepath || data.main.frontmatter.image_filepath) ?
      <img src={utils.getImageURL(data.main.frontmatter.photo_filepath || data.main.frontmatter.image_filepath, 1000)} alt={data.main.frontmatter.title} className="hero" />
      : null
    }
    <div className="contentwithsidebar">
      <div className="contentwithsidebar-content">
        <div className="mobilepadding">
          <h1>{data.main.frontmatter.title}</h1>
          <SocialLinks frontmatter={data.main.frontmatter} />
          <h3>Biography</h3>
          <div dangerouslySetInnerHTML={{ __html: data.main.html || 'x'}} />
        </div>
      </div>
      <div className="contentwithsidebar-sidebar">
        <ReleaseListComponent title="Releases" list={findReleases(allReleases, data.main.frontmatter.nid)} />
        <ReleaseListComponent title="Featured on" list={findFeaturedOn(allReleases, data.main.frontmatter.nid)} />
      </div>
    </div>
  </Layout>
}

export default ArtistComponent
